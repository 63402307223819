import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { root } from "baobab-react/higher-order";
import KendoCore from "kendo-ui-core/js/kendo.core";
import _ from "lodash";
import { Component, lazy, Suspense } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

import ComponentLoader from "components/common/ComponentLoader";
import KendoNotification from "components/common/kendo/KendoNotification";
import customCultures from "config/customCultures";
import { queryClient } from "lib/query-client";
import { Redirect } from "lib/reactRouter";
import tree from "services/AppStateSvc";

import AppErrorBoundary from "./AppErrorBoundary";

import "./App.scss";

function setKendoCultures() {
  _.merge(KendoCore.cultures, customCultures);
  KendoCore.culture("international");
}

const PublicApp = lazy(() => import("./PublicApp"));
const ProtectedApp = lazy(() => import("./ProtectedApp"));
const CreativesPreviewApp = lazy(() => import("./CreativesPreviewApp"));

class App extends Component {
  constructor(props) {
    super(props);
    setKendoCultures();
  }

  render() {
    return (
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <div id="app">
          <HashRouter>
            <Suspense fallback={<ComponentLoader isLoading />}>
              <Routes>
                <Route index element={<Redirect to="/dashboard" />} />
                <Route
                  path="/auth/*"
                  element={
                    <AppErrorBoundary key="publicApp">
                      <PublicApp />
                    </AppErrorBoundary>
                  }
                />
                <Route
                  path="/preview/*"
                  element={
                    <AppErrorBoundary>
                      <CreativesPreviewApp />
                    </AppErrorBoundary>
                  }
                />
                <Route
                  path="*"
                  element={
                    <AppErrorBoundary>
                      <ProtectedApp />
                    </AppErrorBoundary>
                  }
                />
              </Routes>
            </Suspense>
          </HashRouter>
          <KendoNotification
            config={{
              button: true,
              position: {
                top: 60,
                right: 30,
              },
              width: 330,
            }}
          />
        </div>
      </QueryClientProvider>
    );
  }
}

export default root(tree, App);
