import Baobab from "baobab";
import _ from "lodash";

import defaults from "config/defaults";
import { menuItems } from "config/navigation";
import { defaultCurrency } from "lib/consts";
import dictsHelpers from "lib/dictsHelpers";

import { UTC_TIMEZONE_VALUE } from "../lib/helpers/timezone";

const tree = new Baobab({
  config: defaults.config,
  filters: defaults.filters,
  nag: null,
  isBusy: Baobab.monkey(["http", "pendingRequestsCount"], (count) => count > 0),
  http: {
    pendingRequestsCount: 0,
  },
  navigation: {
    menu: menuItems.filter(({ name }) => ["account", "logout"].includes(name)).map(({ config }) => config),
    submenu: {},
  },
  user: {
    info: {},
    isEmployee: Baobab.monkey(["user", "info", "isClientUser"], (isClientUser) =>
      _.isNil(isClientUser) ? isClientUser : !isClientUser,
    ),
    preferences: Baobab.monkey(["user", "info", "preferences"], (preferences) => ({
      currency: preferences?.currency ?? defaultCurrency,
      timezone: preferences?.timezone ?? UTC_TIMEZONE_VALUE,
      columns: preferences?.columns ?? {},
    })),
  },
  dicts: {},
  enums: Baobab.monkey(["dicts"], (dicts) => dictsHelpers.prepareEnums(dicts)),
  helpDict: null,
  advertisers: null,
  advertisersByHash: Baobab.monkey(["advertisers"], (advertisers) =>
    (advertisers || []).reduce((acc, advertiser) => {
      acc[advertiser.hash] = advertiser;
      return acc;
    }, {}),
  ),
  advertiserHash: null,
  advertiser: {
    info: null,
    campaigns: null,
    unarchivedCampaigns: null,
    offerCategories: null,
    selfCreatedCampaigns: null,
    rateCards: null,
  },
  advertiserComputed: {
    offerCategoriesById: Baobab.monkey(["advertiser", "offerCategories"], (cat) =>
      _.isArray(cat) ? _.keyBy(cat, "categoryId") : null,
    ),
    rateCards: Baobab.monkey(["advertiser", "rateCards"], ["advertiser", "campaigns"], (rateCards, campaigns) => {
      if (!rateCards || !campaigns) {
        return null;
      }
      const rateCardIdToCampaignMap = _.chain(campaigns)
        .filter("rateCardId")
        .reduce((result, c) => {
          result[c.rateCardId] = c;
          return result;
        }, {})
        .value();
      return _.chain(rateCards)
        .filter((rc) => rateCardIdToCampaignMap[rc.id])
        .map((rc) => {
          const camp = rateCardIdToCampaignMap[rc.id];
          return {
            ...rc,
            campaignName: camp.name,
            campaignHash: camp.hash,
          };
        })
        .value();
    }),
    rateCardsMap: Baobab.monkey(["advertiserComputed", "rateCards"], (rateCards) =>
      _.reduce(
        rateCards,
        (obj, rc) => {
          obj[rc.campaignHash] = rc;
          return obj;
        },
        {},
      ),
    ),
  },
  dateRangeFilterCache: {},
});

export default tree;
